// Minty 5.3.0
// Bootswatch


// Variables

$web-font-path: "https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;700&display=swap" !default;
@if $web-font-path {
  @import url($web-font-path);
}

:root {
  color-scheme: light;
}


// Navbar

.navbar {
  font-family: $headings-font-family;
  font-size: 1.1rem;
}

.bg-dark {
  background-color: $secondary !important;
}

.border-dark {
  border-color: $secondary !important;
}

// Buttons

.btn {
  font-family: $headings-font-family;

  &,
  &:hover {
    color: $white;
  }

  &-light,
  &-light:hover {
    color: $gray-700;
  }

  &-link,
  &-link:hover {
    color: $primary;
  }

  &-link.disabled:hover {
    color: $gray-600;
  }

  &-outline-primary {
    color: $primary;
  }

  &-outline-secondary {
    color: $secondary;
  }

  &-outline-success {
    color: $success;
  }

  &-outline-info {
    color: $info;
  }

  &-outline-warning {
    color: $warning;
  }

  &-outline-danger {
    color: $danger;
  }

  &-outline-dark {
    color: $dark;
  }

  &-outline-light {
    color: $light;
  }
}

// Forms

legend {
  font-family: $headings-font-family;
}

// Navs

.dropdown-menu {
  font-family: $font-family-sans-serif;
}

.breadcrumb {
  a {
    color: $navbar-dark-color;
  }

  a:hover {
    color: $white;
    text-decoration: none;
  }
}

// Indicators

.alert {
  a,
  .alert-link {
    color: $white;
  }

  &-light {

    &,
    a:not(.btn),
    .alert-link {
      color: $body-color;
    }
  }
}

.badge {
  //color: $white;

  &.bg-light {
    color: $gray-700;
  }
}

// Containers

.card,
.list-group-item {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }
}

// Customization for buttons
.btn {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

// Customize form inputs
.form-control {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

p {
  font-family: $font-family-serif;
  line-height: 1.5rem;
  margin-bottom: 1.2rem;
}

li {
  font-family: $font-family-serif;
  line-height: 2rem;
}

@keyframes moveGradient {
  0% {
    background-position: -100% 50%; // Move the gradient outside the left edge
  }
  50% {
    background-position: 200% 50%; // Move the gradient outside the right edge
  }
  100% {
    background-position: -100% 50%; // Return the gradient to the starting point
  }
}

.shiny-button-primary-white {
  background: linear-gradient(90deg, $primary, lighten($primary, 15%), $primary);
  background-size: 200% auto;
  transition: background 0.5s;
  animation: moveGradient 15s linear infinite;
}

.shiny-button-white-gray {
  background: linear-gradient(90deg, $white, darken($white, 15%), $white);
  background-size: 200% auto;
  transition: background 0.5s;
  animation: moveGradient 10s linear infinite;
}

$font-size-xl: $font-size-base * 2 !default;
$line-height-xl: 1.5;
$btn-padding-y-xl: .5rem !default;
$btn-padding-x-xl: 1.5rem !default;
$btn-font-size-xl: $font-size-xl !default;
$btn-line-height-xl: $line-height-xl !default;
$btn-border-radius-xl: $border-radius-xl !default;

.btn-xl {
  @include button-size($btn-padding-y-xl, $btn-padding-x-xl, $btn-font-size-xl, $btn-line-height-xl, $btn-border-radius-xl);
}

.section-white {
  background: #fff;
  padding: 60px 0;
}

.section-grey {
  background: #fafbfc;
  padding: 60px 0;
}

.section-grey-20 {
  background: #fafbfc;
  padding: 20px 0;
}

.section-primary {
  background-color: $primary;
  color: $white;
  padding: 60px 0;
}

.feature-box i {
  margin-bottom: 0.6rem;
  font-size: 3rem;
  color: $primary;
}

.dropcaps-circle {
  float: left;
  margin-right: 30px;
  background-color: #fcfcfc;
  border: 1px solid #eaeaea;
  width: 100px;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  color: $primary;
  font-size: 50px;
  line-height: 100px;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  transition: all .50s ease-in-out;
  -moz-transition: all .50s ease-in-out;
  -webkit-transition: all .50s ease-in-out;
}

.section-top-border {
  border-top: 1px solid #efefef;
}

.section-subtitle {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
  padding: 1rem 0;
}

.price-box {
  text-align: center;
  background-color: #fff;
  border-bottom: 3px solid #e0e0e0;
  box-shadow: rgba(33, 33, 33, .06) 0 4px 24px 5px;
  padding: 40px 0 30px 0;
  margin-bottom: 20px;
  border-radius: 8px 8px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.price-box:hover {
  border-bottom: 3px solid $primary;
  transition: all 1s;
}

.price-box .price-subtitle, .price-box .price-text {
  color: #6a6a6a;
}

ul.pricing-list {
  padding: 0 55px;
  margin: 0;
}

ul.pricing-list li {
  list-style: none;
}

ul.pricing-list li i {
  color: $primary;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-right: 10px;
}

ul.pricing-list li.price-title {
  font-size: 1.75rem;
  line-height: 2rem;
  font-weight: 700;
  letter-spacing: 1px;
  color: #071e55;
}

ul.pricing-list li.price-value {
  font-family: 'Dosis', sans-serif;
  font-size: 70px;
  line-height: 70px;
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #071e55;
}

ul.pricing-list li.price-subtitle {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #6a6a6a;
}

ul.pricing-list li.price-text {
  display: block;
  text-align: left;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 5px;
}

ul.pricing-list li.price-tag a {
  color: #fff;
  background: lighten($primary, 10%);
  border: 1px solid $primary;
  border-radius: 5px 5px;
  padding: 15px 30px;
  display: inline-block;
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  margin: 30px 0 5px 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

ul.pricing-list li.price-tag a:hover {
  background: $primary;
}

ul.pricing-list li.price-tag-line a, .pricing-fake-button {
  color: $primary;
  background: transparent;
  border: 1px solid $primary;
  border-radius: 5px 5px;
  padding: 15px 30px;
  display: inline-block;
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  margin: 30px 0 5px 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.pricing-fake-button {
  border: 0;
  font-size: 24px;
  margin: 5px;
  padding: 5px;
}

ul.pricing-list li.price-tag-line a.pricing-login-link, ul.pricing-list li.price-tag a.pricing-login-link {
  color: $black;
  background: transparent;
  font-size: 20px;
  line-height: 24px;
  border: 0;
  margin: 0;
  padding: 0;
}

ul.pricing-list li.price-tag-line a.pricing-login-link:hover, ul.pricing-list li.price-tag a.pricing-login-link:hover {
  color: $black;
  background: transparent;
  border: 0;
}

ul.pricing-list li.price-tag-line a:hover {
  color: #fff;
  background: $primary;
  border: 1px solid $primary;
}

.ribbon {
  position: absolute;
  right: 8px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 20px;
  transform: rotate(45deg);
  width: 100px;
  display: block;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
  background-color: $primary;
}

.ribbon span::before {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79A70A;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
  border-left-color: $primary;
  border-top-color: $primary;
}

.ribbon span::after {
  content: '';
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -1;
  border-right: 3px solid #79A70A;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
  border-right-color: $primary;
  border-top-color: $primary;
}

.line-through-text {
  position: relative;
  text-decoration: none;
}

.line-through-text::before,
.line-through-text::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 30px; /* Adjust this value to change the length of the lines */
  height: 1px;
  background-color: $primary; /* Line color */
}

.line-through-text::before {
  left: 0;
}

.line-through-text::after {
  right: 0;
}

.line-through-text-long::before,
.line-through-text-long::after {
  width: 100px; /* Adjust this value to change the length of the lines */
}

.navbar-toggler-icon {
  background-image: escape-svg($navbar-primary-toggler-icon-bg);
}

.navbar-toggler {
  color: $primary;
  border-color: $primary;
}

.pricing-list a, .pricing-list a:hover, .pricing-list a:focus, .pricing-list a:active, .pricing-list a:visited {
  text-decoration: none;
  outline: 0;
}


/* -----  a) Newsletter ----- */
.newsletter_form_wrapper {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.newsletter_form_box {
  margin: 0 auto;
  max-width: 730px;
}

.newsletter-form .email_newsletter {
  width: 450px;
  max-width: 60%;
  height: 65px;
  padding: 0 20px;
  border: 2px solid #fff;
  background-color: transparent;
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
  color: #fff;
  font-size: 16px;
  margin: 0;
}

.newsletter-form .submit-button-newsletter {
  width: 100px;
  max-width: 165px;
  height: 65px;
  background: #fff;
  border: none;
  color: $primary;
  display: inline;
  margin: 0 0 0 -5px;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  font-weight: 700;
  vertical-align: top;
  transition: background-color .25s ease-in-out;
  -moz-transition: background-color .25s ease-in-out;
  -webkit-transition: background-color .25s ease-in-out;
}

.newsletter-form .submit-button-newsletter:hover {
  cursor: pointer;
  color: $primary;
  background-color: #fff;
  transition: background-color .25s ease-in-out;
  -moz-transition: background-color .25s ease-in-out;
  -webkit-transition: background-color .25s ease-in-out;
}

input.email_newsletter::-webkit-input-placeholder { /* WebKit browsers */
  color: #fff;
}

input.email_newsletter:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #fff;
}

input.email_newsletter::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #fff;
}

input.email_newsletter:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #fff;
}

.newsletter_success_box {
  font-size: 18px;
  font-style: italic;
  color: #fff;
  margin: 5px 0;
}

.home-section {
  width: 100%;
  position: relative;
  text-align: center;
  padding-top: 240px;
  padding-bottom: 200px;
  /*background: url(../images/bg10.jpg) top center no-repeat;*/
  background-size: cover;
}

.home-section-overlay {
  width: 100%;
  background: $primary;
  opacity: 0.82;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.faq-card {
  border: none;
  background-color: transparent;
}

.faq-card-header {
  padding: 6px 0 9px 0;
  margin-bottom: 0 !important;
  background-color: transparent;
  border-bottom: 1px solid lighten(#071e55, 10%)
}

.faq-card-header h5 .btn-link {
  padding: 0;
  font-size: 19px;
  font-weight: 600;
  color: #071e55;
  background-color: transparent;
  box-shadow: none;
  white-space: nowrap;
}

.faq-card-header h5 .btn-link:hover, .faq-card-header h5 .btn-link:focus {
  text-decoration: none;
}

.faq-card-body {
  padding: 10px 0;
  border-top: 2px solid $primary;
  /*font-family: 'Open Sans', sans-serif;*/
  font-size: 17px;
  color: #7c8595;
}

.mark-primary {
  background-color: lighten($primary, 45%);
  color: $white;
}

.text-black-bold {
  color: $black;
  font-weight: 700;
}

.margin-top-dashboard {
  margin-top: 80px;
}

.dashboard-rounded {
  border-radius: 0.4rem;
  border: 1px solid $primary;
}

.label-black-bold label, .label-black-bold legend {
  color: $black;
  font-weight: 700;
}