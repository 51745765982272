html{
    scroll-behavior:smooth;
}
.toc a{display:block;}

.back-to-top{
    position:fixed;
    bottom:25px;
    right:25px;
}

.tableFixHead{
    overflow-y:auto;
    height:50vh;
}

.tableFixHead table{
    border-collapse:collapse;
    width:100%;
}

.tableFixHead th,
.tableFixHead td{
    padding:8px 16px;
}

.tableFixHead thead{
    position:sticky;
    z-index:1;
    top:0;
}

.fixed-top.opaque .navbar-nav .current a{
    -webkit-transition:all 0.5s ease;
    -moz-transition:all 0.5s ease;
    -o-transition:all 0.5s ease;
    transition:all 0.5s ease;
}

.features-second{
    margin:2.5rem 0;
}

.page-item.disabled {
    cursor:not-allowed;
}