// Minty 5.3.0
// Bootswatch

$theme: "minty" !default;

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #f7f7f9 !default;
$gray-300: #eceeef !default;
$gray-400: #ced4da !default;
$gray-500: #aaa !default;
$gray-600: #888 !default;
$gray-700: #5a5a5a !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ff7851 !default;
$orange: #fd7e14 !default;
$yellow: #ffce67 !default;
$green: #56cc9d !default;
$teal: #20c997 !default;
$cyan: #6cc3d5 !default;

// Main Colors
$primary: #8B0000; // Dark Red
$secondary: #A9A9A9; // Silver Gray
$success: #27AE60; // Deep Green
$danger: #641E16; // Darker Red
$info: #1C2833; // Dark Blue
$warning: #AF601A; // Dark Gold
$light: #F2F3F4; // Soft Gray
$dark: #1C1C1C; // Near Black
$white: #fff;
$black: #000;

$min-contrast-ratio: 1.45 !default;

// Body

$body-color: $gray-600 !default;

// Components

$border-radius: .4rem !default;
$border-radius-lg: .6rem !default;
$border-radius-xl: .8rem !default;
$border-radius-sm: .3rem !default;

// Fonts

// stylelint-disable-next-line value-keyword-case
$headings-font-family: "Twemoji Country Flags", "Dosis", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$headings-color: $gray-700 !default;
$font-family-serif: "Twemoji Country Flags", Georgia, Cambria, "Times New Roman", Times, serif !default;

// Tables

$table-border-color: rgba(0, 0, 0, .05) !default;

$table-bg-scale: 0% !default;

// Dropdowns

$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $secondary !default;

// Navbar

$navbar-dark-color: rgba($white, .6) !default;
$navbar-dark-hover-color: $white !default;
$navbar-light-color: rgba($black, .3) !default;
$navbar-light-hover-color: $gray-700 !default;
$navbar-light-active-color: $gray-700 !default;
$navbar-light-disabled-color: rgba($black, .1) !default;
$navbar-padding-x: 1rem !default;
$navbar-padding-y: 1rem !default;
$navbar-brand-font-size: 1.5rem !default;
$navbar-nav-link-padding-x: 1rem !default;

// Pagination

$pagination-color: $primary !default;
$pagination-bg: $white !default;
$pagination-border-color: $primary !default;
$pagination-hover-color: $white !default;
$pagination-hover-bg: $primary !default;
$pagination-hover-border-color: $pagination-hover-bg !default;
$pagination-active-bg: $primary !default;
$pagination-active-border-color: $pagination-active-bg !default;
$pagination-disabled-color: $white !default;
$pagination-disabled-bg: $gray-500 !default;
$pagination-disabled-border-color: $pagination-color !default;

// Alerts

$alert-color-scale: 0% !default;
$alert-bg-scale: 0% !default;

// Breadcrumbs

$breadcrumb-padding-y: .375rem !default;
$breadcrumb-padding-x: .75rem !default;
$breadcrumb-bg: $primary !default;
$breadcrumb-divider-color: $white !default;
$breadcrumb-active-color: $breadcrumb-divider-color !default;
$breadcrumb-border-radius: .25rem !default;


// Extra small devices (portrait phones)
$container-max-width-xs: 100%; // Full width on extra small devices

// Small devices (landscape phones)
$container-max-width-sm: 540px; // Max width for small devices

// Medium devices (tablets)
$container-max-width-md: 720px; // Max width for medium devices

// Large devices (desktops)
$container-max-width-lg: 960px; // Max width for large devices

// Extra large devices (large desktops)
$container-max-width-xl: 1280px; // Max width for extra large devices

$container-max-widths: (
        sm: $container-max-width-sm,
        md: $container-max-width-md,
        lg: $container-max-width-lg,
        xl: $container-max-width-xl, // 1440px, // Change to 1440px for XL breakpoint
);

$navbar-primary-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$primary}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;